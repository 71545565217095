import React from "react"
import { Link } from "gatsby"
const KalanjiyamPage = () => {

    return (

        <>
            <div className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>களஞ்சியம்</h2>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>களஞ்சியம்</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="team-section pt-70 pb-70">
                <div className="container">
                    <div className="team-title-area">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="section-title text-left">
                                    <span>
                                        <i className="flaticon-support" />
                                        Our Literatures
                                    </span>
                                    <h2>களஞ்சியம்</h2>

                                </div>
                            </div>
                            <div className="col-lg-5">

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 col-lg-6">
                            <p>
                                அறமும்  செறிவும் வளமும் வாய்ந்த தொன்மையான பண்பட்ட மொழி நம் தமிழ் மொழி.  இரண்டாயிரம் ஆண்டுகளுக்கும் மேலான, உயரிய, மிக நீண்ட நெடிய கல்வியறிவு பாரம்பரியத்தைக் கொண்டுள்ள தமிழர் நாகரிகத்தின் பெருமைமிகு மொழி தமிழ்.
                            </p>
                            <p>
                                பழமைக்கும் பழமையாய் இலக்கிய வளமுடையதாய் நிற்பதோடு புதுமைக்கும் புதுமையாய் கருத்துச் செல்வம் நிறைந்ததாய் என்றும் இளமைப் பொலிவுடன் விளங்குவது செம்மொழியான நம்  தமிழ் மொழியாகும்.
                            </p>
                            <p>
                                தமிழின் தொன்மையையும் இனிமையையும் அறிந்து அனுபவித்து மேலும் செழிப்புடையதாக்குவோம் வாருங்கள்
                            </p>
                        </div>
                        <div className="col-6 col-lg-6">
                            <img src={"https://srirangaminfo.com/cal/" + new Date().getFullYear() + "/" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "" + ("0" + new Date().getDate()).slice(-2) + ".jpg"} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 col-md-12">
                            <ul>
                                <li><a href="https://tamil-desiyam.com/" target="_blank">Tamil Desiyam</a></li>
                                <li><a href="https://dthirukkural.blogspot.com/p/index.html" target="_blank">Thirukural</a></li>
                                <li><a href="https://www.projectmadurai.org/" target="_blank">Project Madurai</a></li>
                                <li><a href="http://www.edubilla.com/tamil/" target="_blank">Tamil Literature</a></li>
                                <li><a href="http://www.tamilpdfbooks.com/" target="_blank">Tamil PDF Books</a></li>
                                <li><a href="https://tamilbookshelf.com/" target="_blank">Tamil Book Shelf</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default KalanjiyamPage
