import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import KalanjiyamPage from "../components/kalanjiyam/index";
const kalanjiyum = () => {
  return (
    <Layout>
      <SEO
        title="களஞ்சியம் | Kentucky Tamil Sangam"
        desc="Kalanjiyam | Kentucky Tamil Sangam"
        pathname="/kalanjiyum"
        keywords="kyts kalanjiyum"
      />
      <KalanjiyamPage />
    </Layout>
  );
};

export default kalanjiyum;
